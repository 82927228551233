import React from "react";
import { MbmProvider } from "@amzn/react-arb-tools";
import {
  DEFAULT_LOCALE,
  TRANSLATION_ROUTE,
  TRANSLATIONS_DIR_NAMESPACE,
} from "../constants/constants";
import { LocalizationContextBuilder } from "@amzn/arb-tools";
import arbManifest from "../i18n/translations/arbManifest";

interface Props {
  children: React.ReactElement;
}
export default function LocalizationMessageProvider(props: Props) {
  let urlPrefix;
  let resolveBundleUrl;
  if (window.location.hostname === "localhost") {
    urlPrefix = TRANSLATION_ROUTE;
  } else {
    resolveBundleUrl = (url?: string) =>
      url
        ? `https://gamma-pdx.selfservice.security.amazon.dev/${TRANSLATIONS_DIR_NAMESPACE}/${url}`
        : undefined;
  }

  const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
      .withLocale(DEFAULT_LOCALE)
      .withDefaultLocale(DEFAULT_LOCALE)
      .build(),
    urlPrefix,
    resolveBundleUrl,
  };

  return <MbmProvider {...mbmOptions}>{props.children}</MbmProvider>;
}
