export const BETA_AUTH_URL =
  "federate-tpsstaffaugmentationservice-beta.auth.us-west-2.amazoncognito.com";
export const BETA_GRAPHQL_ENDPOINT =
  "https://om7zsj2ginertjbzb36wp2q6iq.appsync-api.us-west-2.amazonaws.com/graphql";
export const GAMMA_GRAPHQL_ENDPOINT =
  "https://ezeoartwz5cvlfymaxlei23tle.appsync-api.us-west-2.amazonaws.com/graphql";
export const PROD_GRAPHQL_ENDPOINT =
  "https://iarnkazmh5cjrbb3ngifgrsyny.appsync-api.us-west-2.amazonaws.com/graphql";
export const TT_URL =
  "https://t.corp.amazon.com/create/templates/d2d8a0e0-5c6c-4dad-a772-021b01fe58c1";

export const SERVICE_NOW_IRM_DEV = "https://dev.sn.irm.a2z.com/";
export const SERVICE_NOW_IRM_UAT = "https://uat.sn.irm.a2z.com/";
export const SERVICE_NOW_IRM_PROD = "https://irm.a2z.com/";

export const BASE_URL = "https://gamma-pdx.selfservice.security.amazon.dev";
