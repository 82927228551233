import { Auth } from "aws-amplify";
import {
  BETA_GRAPHQL_ENDPOINT,
  GAMMA_GRAPHQL_ENDPOINT,
  PROD_GRAPHQL_ENDPOINT,
} from "../constants/urlConstants";
import { Stage } from "../constants/constants";
import { BASE_URL } from "../constants/urlConstants";

export const BETA_USER_POOL_ID = "us-west-2_q9qEMOnsb";
export const BETA_WEB_APP_CLIENT_ID = "7skdaalgavaavpcrcg2htrrr5q";

export const GAMMA_USER_POOL_ID = "us-west-2_HrUXO9ktg";
export const GAMMA_WEB_APP_CLIENT_ID = "7u7fa34edk3hjrphbegl6p8a3d";

export const PROD_USER_POOL_ID = "us-west-2_xuYLliPtB";
export const PROD_WEB_APP_CLIENT_ID = "25v9i23i64utp6et4bkuju84p1";

const getAuthorizationHeader = async () => ({
  Authorization: (await Auth.currentAuthenticatedUser())
    .getSignInUserSession()
    .getIdToken()
    .getJwtToken(),
});
const oauthCommon = Object.freeze({
  domain:
    "federate-tpsstaffaugmentationservice-gamma.auth.us-west-2.amazoncognito.com",
  scope: [
    "email",
    "openid",
    "phone",
    "staff-augmentation-service/auth",
    "aws.cognito.signin.user.admin",
    "profile",
  ],
  responseType: "code",
  redirectSignIn: BASE_URL,
  redirectSignOut: BASE_URL,
});

const amplifyCommon: any = Object.freeze({
  authenticationFlowType: "USER_SRP_AUTH",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

const amplifyBeta: any = Object.freeze({
  Auth: {
    mandatorySignId: true,
    region: "us-west-2",
    userPoolId: BETA_USER_POOL_ID,
    userPoolWebClientId: BETA_WEB_APP_CLIENT_ID,
  },
  oauth: {
    ...oauthCommon,
  },
  aws_appsync_graphqlEndpoint: BETA_GRAPHQL_ENDPOINT,
  API: {
    graphql_endpoint: BETA_GRAPHQL_ENDPOINT,
    region: "us-west-2",
    defaultAuthMode: "userPool",
    graphql_headers: getAuthorizationHeader,
  },
  ...amplifyCommon,
});

const amplifyGamma = Object.freeze({
  Auth: {
    mandatorySignId: true,
    region: "us-west-2",
    userPoolId: GAMMA_USER_POOL_ID,
    userPoolWebClientId: GAMMA_WEB_APP_CLIENT_ID,
  },
  oauth: {
    ...oauthCommon,
  },
  aws_appsync_graphqlEndpoint: GAMMA_GRAPHQL_ENDPOINT,
  API: {
    graphql_endpoint: GAMMA_GRAPHQL_ENDPOINT,
    graphql_headers: getAuthorizationHeader,
  },
  ...amplifyCommon,
});

const amplifyProd = Object.freeze({
  Auth: {
    mandatorySignId: true,
    region: "us-west-2",
    userPoolId: PROD_USER_POOL_ID,
    userPoolWebClientId: PROD_WEB_APP_CLIENT_ID,
  },
  oauth: {
    ...oauthCommon,
  },
  aws_appsync_graphqlEndpoint: PROD_GRAPHQL_ENDPOINT,
  API: {
    graphql_endpoint: PROD_GRAPHQL_ENDPOINT,
    graphql_headers: getAuthorizationHeader,
  },
  ...amplifyCommon,
});

export function getAmplifyConfiguration(stage: string): any {
  switch (stage) {
    case Stage.BETA:
      return amplifyBeta;
    case Stage.GAMMA:
      return amplifyGamma;
    case Stage.PROD:
    default:
      return amplifyProd;
  }
}

export default getAmplifyConfiguration;
