import { useQuery } from "@tanstack/react-query";
import KatalLogger, { Level } from "@amzn/katal-logger";
import LoadingSpinner from "@cloudscape-design/components/spinner";
import { createContext, ReactElement, JSX } from "react";
import { cacheConfig, retryConfig } from "../api/APIUtils";
import React from "react";

// Common logger configuration
const commonLoggerConfig = {
  url: "https://d078bqwlzc.execute-api.us-west-2.amazonaws.com/prod/v1/log",
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
  headers: {},
};
const fallbackLogger = new KatalLogger({
  ...commonLoggerConfig,
  logToConsole: true,
});

// This initial value should never be used
const LoggerContext = createContext<KatalLogger>(fallbackLogger);

// function to create a KatalLogger instance
export const createLogger = async (
  fetchToken: () => Promise<string>
): Promise<KatalLogger> => {
  const authToken = await fetchToken();
  const katalLoggerConfig = {
    ...commonLoggerConfig,
    headers: {
      Authorization: authToken,
    },
  };
  const logger = new KatalLogger(katalLoggerConfig);
  logger.addErrorListener();
  return logger;
};

export const LoggerProvider = ({
  fetchToken,
  children,
}: {
  fetchToken: () => Promise<string>;
  children: ReactElement;
}): JSX.Element => {
  const { status, data: logger } = useQuery<KatalLogger>({
    queryKey: ["logger"],
    queryFn: () => createLogger(fetchToken),
    ...retryConfig,
    ...cacheConfig,
  });

  switch (status) {
    case "error":
      console.error("Logger failed to initialize");
      return (
        <LoggerContext.Provider value={fallbackLogger}>
          {children}
        </LoggerContext.Provider>
      );
    case "loading":
      return <LoadingSpinner size="large" />;
    case "success":
      return (
        <LoggerContext.Provider value={logger}>
          {children}
        </LoggerContext.Provider>
      );
    default:
      return <>{children}</>;
  }
};
