import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeTable from "@cloudscape-design/components/table";
import CloudScapePagination from "@cloudscape-design/components/pagination";
import CloudScapePropertyFilter from "@cloudscape-design/components/property-filter";
import { getSecurityTeamsPageTableColumnDefinition } from "./SecurityTeamsPageTableColumnDefinition";
import {
  TableNoMatchState,
  TableEmptyState,
} from "../../components/Tables/TableComponents";
import {
  FullPageHeader,
  FormHandlerType,
} from "../../components/Header/FullPageHeader";
import {
  getTextFilterCounterText,
  getHeaderCounterText,
} from "../../utils/HeaderUtils";
import { decodeString, getStatusType } from "../../utils/CommonUtils";
import { getTPSSecurityTeamId } from "../../utils/TPSConfigUtils";
import {
  PAGE_TITLE,
  PAGE_SIZE,
  SecurityTeamFields,
  RECORD_LIMITS,
  FORM_MODE,
  SECURITY_CONFIG_TEAM_ID_URL_FRAGMENT,
  SecurityTeamFieldsFilterText,
} from "../../constants/constants";
import { useGetSecurityTeams } from "../../api/securityTeams/getSecurityTeamsAPI";
import { SecurityTeam } from "../../api/API";
import { getSecurityTeamsPageFilteringProperties } from "./SecurityTeamsPageFilteringProperties";
import { SECURITY_TEAM_CONFIG_PAGE } from "../../constants/linkConstants";
import { TicketModal } from "../../components/Modal/TicketModal";
import {
  LdapUpdates,
  promptUpdateTicketGenerator,
} from "../../utils/ModalUtils";

export interface SecurityTeamsTableRecord {
  securityTeamId: string;
  teamName: string;
  taskTagNames: string;
  disableTier3Automation: string;
  disableTier4Automation: string;
  isActive: string;
  lastUpdated: string;
  updatedBy: string;
}

export const SecurityTeamsPageTable = withBundle(
  "pages.SecurityTeamsPageTable"
)((props: WithBundleProps) => {
  const [selectedSecurityTeams, setSelectedSecurityTeams] = useState<
    SecurityTeamsTableRecord[]
  >([]);
  const [ldapUpdates, setLdapUpdates] = useState<LdapUpdates>(null);
  const { data, isLoading, isFetched, isSuccess, refetch } =
    useGetSecurityTeams(RECORD_LIMITS);
  const { promptCutTicket, idToSecurityTeam } = useMemo(() => {
    const idToSecurityTeam = new Map<string, SecurityTeam>();
    if (isFetched && isSuccess)
      data.forEach(
        (securityTeam) =>
          !!securityTeam.securityTeamId &&
          idToSecurityTeam.set(securityTeam.securityTeamId, securityTeam)
      );
    return {
      promptCutTicket: promptUpdateTicketGenerator(
        setLdapUpdates,
        idToSecurityTeam
      ),
      idToSecurityTeam,
    };
  }, [data, isFetched, isSuccess]);

  const filteringProperties = useMemo(() => {
    return getSecurityTeamsPageFilteringProperties(props.bundle);
  }, [props.bundle]); //To avoid unnecessary re-render's due to change in reference
  const navigate = useNavigate();

  //For Hydra Phase 3B Phase 1 we have no pagination we are retrieving all Security Teams to the frontend and applying pagination, filtering, sorting using cloudscape useCollection hook
  //As an fast follow we can deliver pagination move all this logic to Backend to perform filtering, sorting for us
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(
    Array.from(idToSecurityTeam.values()).map((securityTeam: SecurityTeam) => {
      return {
        securityTeamId: securityTeam.securityTeamId!,
        teamName: decodeString(securityTeam.teamName!),
        taskTagNames: decodeString(securityTeam.taskTagNames?.join(", ") ?? ""),
        disableTier3Automation: securityTeam.disableTier3Automation!
          ? props.bundle.getMessage("active")
          : props.bundle.getMessage("in_active"),
        disableTier4Automation: securityTeam.disableTier4Automation!
          ? props.bundle.getMessage("active")
          : props.bundle.getMessage("in_active"),
        isActive: securityTeam.isActive!
          ? props.bundle.getMessage("active")
          : props.bundle.getMessage("in_active"),
        lastUpdated: props.bundle.formatMessage("last_updated_date", {
          lastUpdated: new Date(0).setUTCSeconds(securityTeam.lastUpdated!),
        }),
        updatedBy: securityTeam.updatedBy!,
      };
    }),
    {
      pagination: { pageSize: PAGE_SIZE },
      sorting: {},
      propertyFiltering: {
        filteringProperties: filteringProperties,
        empty: (
          <TableEmptyState
            contentName={PAGE_TITLE.SECURITY_TEAMS}
            refetch={refetch}
          />
        ),
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => actions.setFiltering("")}
            page={PAGE_TITLE.SECURITY_TEAMS}
          />
        ),
      },
      selection: {
        defaultSelectedItems: selectedSecurityTeams,
      },
    }
  );

  //Update Selected Security Teams Data if Security Teams table record items got updated when API [data] is refetched again by tanStackQuery - Cache stale timeout or Mutation Invalidation action
  useEffect(() => {
    if (items) {
      setSelectedSecurityTeams((prevSelectedSecurityTeams) =>
        prevSelectedSecurityTeams.filter(
          (selectedSecurityTeam: SecurityTeamsTableRecord) =>
            items.some(
              (item: SecurityTeamsTableRecord) =>
                item.securityTeamId === selectedSecurityTeam.securityTeamId //securityTeamId Uniquely identifies table records
            )
        )
      );
    }
  }, [data]); //items depend on [data] which is memoized by tanStack Query so added it instead of items

  //Need to add logic here for Security Team's Page Form Handling
  const formHandler: FormHandlerType = (formMode, securityTeamRecord) => {
    if (formMode === FORM_MODE.EDIT) {
      const editSecurityTeamRecord =
        securityTeamRecord! as SecurityTeamsTableRecord;
      setSelectedSecurityTeams([editSecurityTeamRecord]);
      navigate(
        `${SECURITY_TEAM_CONFIG_PAGE.href}#${SECURITY_CONFIG_TEAM_ID_URL_FRAGMENT}=${editSecurityTeamRecord.securityTeamId}`
      );
    } else {
      navigate(SECURITY_TEAM_CONFIG_PAGE.href);
    }
  };

  return (
    <React.Fragment>
      <TicketModal
        ldapUpdates={ldapUpdates}
        closeModal={() => setLdapUpdates(null)}
      />
      <CloudScapeTable
        {...collectionProps}
        //Security Teams Table Properties
        variant="full-page" //variant table is entire content of page
        stickyHeader={true} //table header remains visible when the user scrolls down
        resizableColumns={true}
        selectionType="multi"
        stickyColumns={{ first: 1, last: 0 }}
        sortingDisabled={isLoading} //disabling user from sorting before loading the table content
        //Table Content
        items={items}
        columnDefinitions={getSecurityTeamsPageTableColumnDefinition(
          props.bundle,
          formHandler
        )}
        isItemDisabled={(item: SecurityTeamsTableRecord) =>
          getTPSSecurityTeamId("gamma") === item.securityTeamId
        }
        //empty slot when table items array is empty
        empty={
          <TableEmptyState
            contentName={PAGE_TITLE.SECURITY_TEAMS}
            refetch={refetch}
          />
        }
        //slot to add Heading element of table container
        header={
          <FullPageHeader
            currentRecords={selectedSecurityTeams}
            totalRecords={getHeaderCounterText(
              isFetched && isSuccess ? data.length : 0,
              selectedSecurityTeams.length
            )}
            page={PAGE_TITLE.SECURITY_TEAMS}
            formHandler={formHandler}
            promptCutTicket={promptCutTicket}
          />
        }
        //Loading condition and UI
        loading={isLoading}
        loadingText={props.bundle.getMessage(
          `loading_${PAGE_TITLE.SECURITY_TEAMS}`
        )}
        //slot to add filter controls for table
        filter={
          <CloudScapePropertyFilter
            {...propertyFilterProps}
            filteringAriaLabel={props.bundle.getMessage(
              `filter_${PAGE_TITLE.SECURITY_TEAMS}`
            )}
            filteringPlaceholder={props.bundle.getMessage(
              `search_${PAGE_TITLE.SECURITY_TEAMS}`
            )}
            filteringLoadingText={props.bundle.getMessage(
              SecurityTeamFieldsFilterText.SECURITY_TEAM_FIELDS_FILTER_LOADING_TEXT
            )}
            filteringErrorText={props.bundle.getMessage(
              SecurityTeamFieldsFilterText.SECURITY_TEAM_FIELDS_FILTER_ERROR_TEXT
            )}
            filteringRecoveryText={props.bundle.getMessage(
              SecurityTeamFieldsFilterText.SECURITY_TEAM_FIELDS_FILTER_RECOVERY_TEXT
            )}
            filteringFinishedText={props.bundle.getMessage(
              SecurityTeamFieldsFilterText.SECURITY_TEAM_FIELDS_FILTER_FINISHED_TEXT
            )}
            filteringStatusType={getStatusType(isLoading, isSuccess)}
            countText={getTextFilterCounterText(
              filteredItemsCount || 0,
              props.bundle
            )}
            onLoadItems={() => {
              //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
              //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
              if (isLoading === false && isSuccess === false) {
                refetch();
              }
            }}
          />
        }
        //slot to add pagination component of table
        pagination={<CloudScapePagination {...paginationProps} />}
        //Table Selection
        onSelectionChange={({ detail }) =>
          setSelectedSecurityTeams(detail.selectedItems)
        }
        selectedItems={selectedSecurityTeams}
        trackBy={SecurityTeamFields.SECURITY_TEAM_ID} //specifies property that uniquely identifies individual item & acts as key for react rendering performance optimization
      />
    </React.Fragment>
  );
});
